import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'

import Layout from '../../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

const googleCloud = () => {
  const data = useStaticQuery(graphql`
    query Cloud_GoogleCloudSEO {
      allContentfulPages(
        filter: {
          pageReference: { pageIdentifier: { eq: "Cloud_GoogleCloud" } }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <StaticImage
        className="home-fixed-top"
        src="../../resources/images/homepage/hero_lens.svg"
        placeholder="blurred"
        alt="Hero Lenses"
      />
      <section className="mt-5 pt-5 pb-5">
        <div className="container-1">
          <div className="row pb-5 mt-3">
            <div className="col-md-10 offset-md-1 text-center pt-5">
              <h1>Lenses on Google Cloud Platform</h1>
              <div className="pr-3">
                <h2 className="hero-description-dark pr-5 mobile-text-center">
                  Lenses is the fastest way to manage your data over Apache
                  Kafka and create Kubernetes Flows, that is now available for
                  Google Cloud Platform.
                </h2>
                <div className="mt-3 roll-button">
                  <a
                    className="bg-red text-white"
                    href="/contact-us/?s=google"
                    id="clouds-google-hero-button-contact-us"
                  >
                    <span data-hover="Talk to our team!">
                      Talk to our team!
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="se-section">
        <div className="container-1 pt-5 pb-5">
          <div className="row mt-4">
            <div className="col-md-8 offset-md-2 text-center ">
              <StaticImage
                className="w-25 mb-3 mx-auto"
                src="../../resources/images/logos-clouds/google-cloud.svg"
                placeholder="tracedSVG"
                alt="google cloud"
              />

              <h2 className="fw-200 m-0 pb-4">
                Deploy on Google Cloud Platform
              </h2>
              <p className="mt-4 mb-5 ">
                Lenses can be deployed in GCP with your own Apache Kafka cluster
                with a Deployment manager template
              </p>
              <a
                className="primary-text"
                href="https://docs.lenses.io/4.3/installation/cloud/gce/"
                id="clouds-aws-technical-documentation"
              >
                Technical documentation<i className="red-pointer-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className=" pt-5 pb-5">
        <div className="container-1">
          <div className="row">
            <div className="col-sm-12 text-center pt-5">
              <h2 className="paragraph-title mb-5 ">
                Deploy Lenses where you want
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-3 offset-md-3 text-center">
              <StaticImage
                className="w-40"
                src="../../resources/images/wizard_form/on-prem.png"
                placeholder="tracedSVG"
                alt="Premises"
              />
              <h2 className="paragraph-title pt-3">On prem</h2>
              <p className=" text-center">
                Install the platform in your <br />
                physical data center.
              </p>
            </div>
            <div className="col-sm-12 col-md-3 text-center">
              <StaticImage
                className="w-40"
                src="../../resources/images/wizard_form/on-cloud.png"
                placeholder="tracedSVG"
                alt="Cloud"
              />
              <h2 className="paragraph-title  pt-3">On cloud</h2>
              <p className=" text-center">
                Deploy the Lenses in your <br />
                virtual private cloud.
              </p>
            </div>
            <div className="col-sm-12 col-md-12 pt-5 pb-5 text-center">
              <a className="primary-text" href="/product/deployment/">
                Deployment options <i className="red-pointer-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default googleCloud

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

const headerSEO = ({ pageSEO }) => {
    return (
        <Helmet>
            <title>{pageSEO.title.title} | Lenses.io</title>
            <meta name='twitter:text:title' content={pageSEO.title.title} />
            <meta name='description' content={pageSEO.description.description} />

            <meta property='og:description' content={pageSEO.description.description} />
            <meta property='twitter:description' content={pageSEO.description.description} />

            <meta name='keywords' content={pageSEO.keywords.keywords} />

            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:site' content='@lensesio' />
            <meta property='og:title' content={pageSEO.title.title} />
            <meta property='twitter:title' content={pageSEO.title.title} />
            <meta property='og:image' content={'https:' + pageSEO.oGimage.fluid.src} />
            <meta property='twitter:image' content={'https:' + pageSEO.oGimage.fluid.src} />
        </Helmet>
    )
}

export default headerSEO

headerSEO.propTypes = {
    pageSEO: PropTypes.object,
}
